// Entry point for the build script in your package.json
import * as Sentry from "@sentry/browser";
import "@hotwired/turbo-rails";
import "./controllers";
import * as bootstrap from "bootstrap";
import "trix";
import "@rails/actiontext";

if (RAILS_ENV === 'production') {
  Sentry.init({
    dsn: "https://28f65f516b8d42b4bc7588d5de3a1c52@o4505583564488704.ingest.sentry.io/4505583605645312",
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["https://www.passagist.com/", "https://passagist.com/"],
  
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

document.addEventListener('turbo:load', function() {
  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
  });
});
